import { ComponentChildren } from "preact";
import { StateUpdater, useEffect } from "preact/hooks";

export type DrawerState = {
  open: boolean;
  setOpen: StateUpdater<boolean>;
  title?: string;
  children: ComponentChildren;
};

export default function BottomDrawer(props: DrawerState) {
  const { open, setOpen } = props;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        id="drawer-bottom"
        class={`fixed w-screen h-full z-40 p-4 overflow-y-auto bg-white dark:bg-gray-800
          transition-all ${open ? "bottom-0" : "-bottom-full"}`}
        aria-labelledby="drawer-bottom-label"
        aria-hidden={!open}
      >
        <button
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          type="button"
          id="close-btn"
          data-drawer-dismiss="drawer-bottom"
          aria-controls="drawer-bottom"
          onClick={() => close()}
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            >
            </path>
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
        <div class="max-w-screen-md mx-auto mb-6 text-sm text-gray-500 dark:text-gray-400">
          <h4
            id="drawer-bottom-label"
            class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
          >
            {props.title}
          </h4>
          {props.children}
        </div>
      </div>
    </>
  );
}
