import { JSX } from "preact";

export default function Input(
  props: JSX.HTMLAttributes<HTMLInputElement> & {
    defaultValue?: string | undefined;
  },
) {
  return (
    <div class={props.class}>
      <label
        class="block tracking-wide text-inherit font-bold mb-1 text-gray-900 dark:text-gray-300"
        for={props.id}
      >
        {props.label}
      </label>
      <input
        {...props}
        class={`shadow appearance-none border rounded text-lg w-full h-12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
          ${props.readonly ? "bg-gray-100" : ""}
        `}
        type={props.type || "text"}
      />
    </div>
  );
}
