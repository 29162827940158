export interface Geocode {
  address: string;
  location: { lat: number; lng: number };
  name: string;
  placeId: string;
}

export class GeocodeApi {
  private readonly route: string;

  constructor(origin: string) {
    this.route = `${origin}/geocodes`;
  }

  async find(query: string): Promise<Geocode[]> {
    const response = await fetch(`${this.route}?q=${query}`);
    return await response.json();
  }
}
