import { Route, route, Router, RouterOnChangeArgs } from "preact-router";
import { signal } from "@preact/signals";
import { useEffect, useState } from "preact/hooks";

import { API_ORIGIN } from "../config";
import { Account, AuthApi } from "../../http_clients/auth_api";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import BusinessPage from "../routes/u";
import Login from "../routes/login";
import Scan from "../routes/scan";
import SignUp from "../routes/sign_up";
import SelectBusiness from "./select_business";

export const user = signal<Account | null>(null);

export default function App() {
  const [businessId, setBusinessId] = useState<string>();

  const getUser = async () => {
    const api = new AuthApi(API_ORIGIN);
    const account = await api.getAccount();
    user.value = account;
    if (!user.value) {
      route("/login");
      return;
    }
    if (!user.value.isAdmin) {
      setBusinessId(user.value.businessId);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleRouterOnChange = (args: RouterOnChangeArgs) => {
    switch (args.url) {
      case "/":
      case "/u":
      case "/scan":
        getUser();
    }
  };

  const handleSelect = (businessId: string) => {
    setBusinessId(businessId);
  };

  return (
    <div id="app">
      <SelectBusiness onSelect={handleSelect} />
      <Router onChange={handleRouterOnChange}>
        {businessId && <Home path="/" businessId={businessId} />}
        {businessId && <BusinessPage path="/u" businessId={businessId} />}
        {businessId && <Scan path="/scan" businessId={businessId} />}

        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
      </Router>
    </div>
  );
}
