import { useEffect, useState } from "preact/hooks";
import { Business, BusinessApi } from "../../http_clients/business_api";
import { API_ORIGIN } from "../config";
import { user } from "./app";
import BottomDrawer from "./bottom_drawer";
import BusinessForm from "./business_form";

export default function SelectBusiness(
  props: { onSelect: (businessId: string) => void },
) {
  const [businesses, setBusinesses] = useState<Business[] | null>(null);

  const getBusinesses = async () => {
    const api = new BusinessApi(API_ORIGIN);
    setBusinesses(await api.list());
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  return (
    <>
      {user.value?.isAdmin && (
        <div class="mx-auto max-w-screen-md flex">
          <select
            onInput={(e) => props.onSelect(e.currentTarget.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected>Choose a Business</option>
            {businesses?.map((b) => (
              <option value={b.id} key={b.id}>{b.name}</option>
            ))}
          </select>
          <AddBusiness onAdd={getBusinesses} />
        </div>
      )}
    </>
  );
}

function AddBusiness(props: { onAdd: () => Promise<void> }) {
  const [open, setOpen] = useState(false);
  const [business, setBusiness] = useState<Business>(defaultBusiness);

  const handleSave = async (business: Business) => {
    const api = new BusinessApi(API_ORIGIN);
    await api.add(business);
    setBusiness(defaultBusiness);
    await props.onAdd();
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        Add
      </button>

      <BottomDrawer open={open} setOpen={setOpen}>
        <BusinessForm
          business={business}
          setBusiness={setBusiness}
          onSave={handleSave}
        />
      </BottomDrawer>
    </div>
  );
}

const defaultBusiness: Business = {
  id: "",
  name: "",
  address: "",
  latLng: { lat: 0, lng: 0 },
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  logoUri: "",
  googlePlaceId: "",
};
