export interface Jwt {
  sub: string;
  business_id: string;
}

export type Account = {
  email: string;
  businessId: string;
  isAdmin: boolean;
};

export class AuthApi {
  constructor(private origin: string) {}

  async login(
    email: string,
    password: string,
  ): Promise<string | null> {
    const response = await fetch(`${this.origin}/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      credentials: "include",
    });
    const jwt = await response.text();
    if (!jwt) return null;
    return jwt;
  }

  async logout(): Promise<void> {
    await fetch(`${this.origin}/logout`, {
      method: "POST",
      credentials: "include",
    });
  }

  async getAccount(): Promise<Account | null> {
    const response = await fetch(`${this.origin}/account`, {
      credentials: "include",
    });

    if (!response.ok) return null;
    
    return await response.json() as Account;
  }

  async createAccount(
    email: string,
    password: string,
  ): Promise<string | null> {
    const response = await fetch(`${this.origin}/accounts`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      credentials: "include",
    });
    const jwt = await response.text();
    if (!jwt) return null;
    return jwt;
  }
}
