import { API_ORIGIN } from "../../config";
import { ImageApi } from "../../../http_clients/image_api";
import { useState } from "preact/hooks";

export default function ImagePicker(props: {
  id: string;
  value: string;
  onInput: (imgUrl: string) => void;
}) {
  const [imgSrc, setImgSrc] = useState<string>();
  setImgSrc(props.value);

  const handleOnInput = async (files: FileList | null) => {
    if (!files || files.length < 1) return;

    const img = files.item(0);
    const api = new ImageApi(API_ORIGIN);
    const imgUrl = await api.addImage(img!);
    props.onInput(imgUrl.href);
    setImgSrc(imgUrl.href);
  };
  return (
    <div class="flex justify-center items-center w-full mb-4">
      {imgSrc && <img src={imgSrc} class="object-scale-down h-48 w-48" />}
      <label
        for={props.id}
        class="flex flex-col justify-center items-center w-full h-40 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div class="flex flex-col justify-center items-center pt-5 pb-6">
          <svg
            class="mb-3 w-10 h-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span class="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            PNG, JPG
          </p>
        </div>
        <input
          class="hidden"
          id={props.id}
          name=""
          type="file"
          accept="image/png, image/jpeg"
          onInput={(e) => handleOnInput(e.currentTarget.files)}
        />
      </label>
    </div>
  );
}
