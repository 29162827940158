export class ImageApi {
  private readonly route: string;

  constructor(origin: string) {
    this.route = `${origin}/images`;
  }

  async addImage(img: File): Promise<URL> {
    const data = new FormData();
    data.append("image_upload", img, img.name);

    const response = await fetch(this.route, {
      method: "POST",
      body: data,
      credentials: "include",
    });
    const body = await response.text();
    return new URL(body);
  }
}
