import { StateUpdater, useState } from "preact/hooks";
import { JSXInternal } from "preact/src/jsx";
import { Business } from "../../http_clients/business_api";
import { Geocode, GeocodeApi } from "../../http_clients/geocode_api";
import { API_ORIGIN } from "../config";
import ImagePicker from "./form_controls/image_picker";
import Input from "./form_controls/input";
import SubmitButton from "./form_controls/submit_button";

export default function BusinessForm(
  props: {
    business: Business;
    setBusiness: StateUpdater<Business>;
    onSave: (business: Business) => Promise<void>;
  },
) {
  const [waiting, setWaiting] = useState(false);

  const handleSave = async () => {
    if (
      !props.business ||
      !props.business.name ||
      !props.business.address
    ) {
      alert("Search business, then select form the results.");
      return;
    }
    setWaiting(true);
    await props.onSave(props.business);
    setWaiting(false);
  };

  const handleInput = (
    e: JSXInternal.TargetedEvent<HTMLInputElement, Event>,
  ) => {
    const { name, value } = e.currentTarget;
    props.setBusiness((b) => ({ ...b, [name]: value }));
  };

  const setImage = (logoUri: string) => {
    props.setBusiness((b) => ({ ...b, logoUri }));
  };

  const setBusiness = (business: Geocode) => {
    props.setBusiness((b) => ({
      ...b,
      latLng: business.location,
      name: business.name,
      address: business.address,
      googlePlaceId: business.placeId,
    }));
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <div class="block p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
        <BusinessSearch onSelect={setBusiness} />
        <Input
          class="w-full mb-4"
          label="Name"
          name="name"
          value={props.business.name}
          onInput={handleInput}
          readonly
        />
        <Input
          class="w-full mb-4 aria-readonly"
          label="Address"
          name="address"
          value={props.business.address}
          onInput={handleInput}
          readonly
        />
      </div>

      <div class="p-4">
        <Input
          class="w-full mb-4"
          label="First Name"
          name="firstName"
          value={props.business.firstName}
          onInput={handleInput}
          required={true}
        />
        <Input
          class="w-full mb-4"
          label="Last Name"
          name="lastName"
          value={props.business.lastName}
          onInput={handleInput}
          required={true}
        />
        <Input
          class="w-full mb-4"
          label="Phone Number"
          name="phone"
          value={props.business.phone}
          onInput={handleInput}
          required={true}
          maxLength={10}
        />
        <ImagePicker
          id="image"
          value={props.business.logoUri}
          onInput={setImage}
        />
        <SubmitButton text="Save" waiting={waiting} />
      </div>
    </form>
  );
}

function BusinessSearch(props: { onSelect: (business: Geocode) => void }) {
  const [search, setSearch] = useState("");
  const [businesses, setBusinesses] = useState<Geocode[]>([]);

  const handleSearch = async () => {
    const api = new GeocodeApi(API_ORIGIN);
    const result = await api.find(search);
    setBusinesses(result);
  };

  const handleSelect = (business: Geocode) => {
    setSearch("");
    setBusinesses([]);
    props.onSelect(business);
  };

  return (
    <div class="mb-4">
      <label class="block tracking-wide text-inherit font-bold mb-1 text-gray-900 dark:text-gray-300">
        Search business
      </label>
      <div class="relative">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            >
            </path>
          </svg>
        </div>
        <input
          type="search"
          id="search"
          class="block p-4 pl-10 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search"
          value={search}
          onInput={(e) => setSearch(e.currentTarget.value)}
        />
        <button
          type="button"
          class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={() => handleSearch()}
        >
          Search
        </button>
      </div>
      {businesses.length > 0 &&
        (
          <div
            id="dropdown"
            class="z-10 w-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
          >
            <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
              {businesses.map((b) => (
                <li class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  <button
                    class="inline"
                    type="button"
                    onClick={() => handleSelect(b)}
                  >
                    <p class="text-left">{b.name}</p>
                    <span class="font-medium truncate">{b.address}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
}
